<template>
  <img
    @click="onIconClick"
    class="cursor-pointer"
    src="../../../../assets/BorrowReturn/info_icon.svg"
    alt=""
  />
</template>

<script>
export default {
  emits: ["icon-click"],
  methods: {
    onIconClick() {
      this.params.onNoteIconClick(this.params.value);
    },
  },
};
</script>
