<template>
  <div class="status-card my-1" :class="statusClass">
    <div class="d-flex align-items-center">
      <div class="dot me-2"></div>
      <p class="m-0">{{ borrowStatus }}</p>
    </div>
  </div>
</template>

<script>
import { AssetStatuses } from "@/constants/borrow-return/AssetStatuses";
export default {
  computed: {
    getValue() {
      return this.params.value;
    },
    borrowStatus() {
      switch (this.getValue) {
        case AssetStatuses.AVAILABLE:
          return this.$t("borrow.free_status");
        case AssetStatuses.UNAVAILABLE:
          return this.$t("borrow.occupied_status");
        case AssetStatuses.NOT_AVAILABLE:
          return this.$t("borrow.not_ready_status");
        default:
          return "-";
      }
    },
    statusClass() {
      switch (this.getValue) {
        case AssetStatuses.AVAILABLE:
          return "free";
        case AssetStatuses.UNAVAILABLE:
          return "occupied";
        case AssetStatuses.NOT_AVAILABLE:
          return "not-ready";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-card {
  width: fit-content;
  padding: 0 20px;
  border-radius: 9999px;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  &.free {
    background-color: #e0f9f5;
    .dot {
      background-color: #17d7b2;
    }
  }
  &.occupied {
    background-color: #fff4d1;
    .dot {
      background-color: #ffa53a;
    }
  }
  &.not-ready {
    background-color: #ffebeb;
    .dot {
      background-color: #e13434;
    }
  }
}
</style>
