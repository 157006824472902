<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
    @sortChanged="onSortChange"
    @rowSelected="onRowSelected"
    @rowDataUpdated="setSelectedNode"
    @selectionChanged="onSelectionChanged"
    :overlayLoadingTemplate="overlayLoadingTemplate"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import assetImg from "../components/assetImg.vue";
import statusCard from "./CellFramework/BorrowStatusCard.vue";
import NoteIcon from "./CellFramework/AssetNoteIcon.vue";
//  Table Components

export default {
  emits: ["interface", "selected-count", "note-click"],
  components: {
    AgGridVue,
    assetImg,
    statusCard,
    NoteIcon,
  },
  data() {
    return {
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Loading</span>',
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      selectedRowData: new Map(),
    };
  },
  props: {
    rowSelection: { required: false, default: "multiple" },
    page: { type: Number },
    perPage: {
      type: Number,
      required: false,
      default: 100,
    },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    otherColumn: { default: () => [] },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        headerCheckboxSelection:
          this.rowSelection === "multiple" ? true : false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: this.rowSelection !== "none" ? true : false,
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("borrow.asset_image"),
        field: "asset_image",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "assetImg",
      },
      {
        headerName: this.$t("home.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.as_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.as_dt"),
        field: "description",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.cat"),
        field: "category",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.sub_cat"),
        field: "sub_category",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("home.build"),
        field: "location_building",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.fl"),
        field: "location_floor",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.department"),
        field: "location_department",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.zone"),
        field: "location_zone",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("borrow.borrow_status"),
        field: "borrow_status",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "statusCard",
      },
      {
        headerName: this.$t("borrow.note"),
        field: "borrow_note",
        sortable: true,
        suppressSizeToFit: true,
        maxWidth: 100,
        cellRenderer: "NoteIcon",
        cellClass: "text-center",
        cellRendererParams: {
          onNoteIconClick: this.onNoteIconClick.bind(this),
        },
      },
    ];
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),

    rowData() {
      return (
        this.assetData?.map((asset, index) => {
          return {
            index: (this.page - 1) * this.perPage + index + 1,
            asset_id: asset.id_asset,
            asset_image: asset.image_url?.url,
            id_fixed_asset: asset.id_fixed_asset,
            borrow_note: asset,
            name: asset.name,
            description: asset?.description || "-",
            borrow_status: asset.request_status,
            sub_category: asset.sub_category?.name || "-",
            category: asset.category?.name || "-",
            location_building: asset.location_building?.name || "-",
            location_department: asset.location_department?.name || "-",
            location_floor: asset.location_floor?.name || "-",
            location_zone: asset.location_zone?.name || "-",
          };
        }) || null
      );
    },
  },
  methods: {
    onNoteIconClick(assetDetail) {
      this.$emit("note-click", assetDetail);
    },
    onSortChange() {
      const colState = this.gridColumnApi.getColumnState();
      const sortState = colState
        .filter(function(s) {
          return s.sort != null;
        })
        .map(function(s) {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          };
        });
      console.log(sortState);
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : null;
    },
    onSelectionChanged(event) {
      this.$emit("selected-count", this.selectedRowData.size);
    },
    onRowSelected(event) {
      if (event.node.selected) {
        this.selectedRowData.set(event.data.asset_id, event.data);
      } else {
        this.selectedRowData.delete(event.data.asset_id);
      }
    },
    setSelectedNode() {
      this.gridApi.forEachNode((node) => {
        if (
          !node.isSelected() &&
          this.selectedRowData.has(node.data.asset_id)
        ) {
          node.setSelected(true, false, false);
        }
      });
    },
    getSelectedRowData() {
      return this.selectedRowData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    emitInterface() {
      this.$emit("interface", {
        getSelectedRowData: () => this.getSelectedRowData(),
        showLoading: () => this.gridApi.showLoadingOverlay(),
        hideLoading: () => this.gridApi.hideOverlay(),
      });
    },
  },
  created() {
    if (this.$route.name === "select-borrow") {
      this.selectedRowData = new Map(
        this.$store.getters["borrow/getBorrowAsset"]
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.emitInterface();
  },
};
</script>

<style scoped>
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
